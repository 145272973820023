<template>
  <section v-loading="loading" class="timeslot">
    <TerminalBackButton class="timeslot__backButton" @clicked="backModal" />

    <div class="timeslot__container">
      <div class="timeslot__title">Дополнительная информация</div>
      <div class="timeslot__truck">Авто:&nbsp;{{ plateTruck }}</div>
      <div class="timeslot__subtitle">
        При необходимости изменить ранее введенные данные - нажмите кнопку Назад
      </div>

      <el-form
        ref="additionalForm"
        class="timeslot__form"
        :model="additionalForm"
        :validate-on-rule-change="false"
      >
        <div v-if="isStep" class="timeslot__form-row">
          <el-form-item
            ref="plate_trailer"
            class="item"
            prop="plate_trailer"
            :rules="rules.plate_trailer"
          >
            <SimpleInput
              v-model="additionalForm.plate_trailer"
              placeholder="Номер прицепа"
              @focus="resetField('plate_trailer')"
            />
            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />
                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>

          <el-form-item
            ref="axle_id"
            class="item"
            prop="axle_id"
            :rules="rules.axle_id"
          >
            <div class="timeslot__select" @click="openAxleSelect">
              <span
                :class="[
                  'timeslot__select-content',
                  currentAxle ? 'value' : 'placeholder',
                ]"
              >
                {{ currentAxle || 'Количество осей' }}
              </span>
              <InputChevronDown />
            </div>
            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />
                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>
        </div>
        <div v-if="terminalRequireInn" class="timeslot__form-row">
          <el-form-item ref="inn" class="item" prop="inn" :rules="rules.inn">
            <SimpleInput
              v-model="additionalForm.inn"
              v-mask="'##########'"
              placeholder="ИНН"
              @focus="resetField('inn')"
            />
            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />
                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>
        </div>

        <MainButton
          class="timeslot__form-button"
          title="Стать в общую очередь"
          type="primary"
          size="big"
          @button-clicked="submit('additionalForm')"
        />
      </el-form>
    </div>

    <div class="simpleKeyboard">
      <SimpleKeyboard
        class="simpleKeyboard__board"
        :input="additionalForm[currentInput]"
        @onChange="onChange"
      />
    </div>

    <SelectOptionsModal
      v-if="isAxleOpen"
      title="Укажите количество осей"
      :list="preparedAxleList"
      :active="additionalForm.axle_id"
      @cancel="isAxleOpen = false"
      @accept="axleSelected"
    />
  </section>
</template>

<script>
import InputChevronDown from '@/assets/icons/InputChevronDown.vue'
import MainButton from '../components/UI/buttons/MainButton.vue'
import SelectOptionsModal from '@/views/terminalUI/components/modals/SelectOptionsModal'
import SimpleInput from '../components/UI/controls/SimpleInput.vue'
import SimpleKeyboard from '../components/UI/controls/SimpleKeyboard'
import TerminalBackButton from '@/views/terminalUI/components/UI/buttons/TerminalBackButton.vue'
import ValidationErrorIcon from '@/assets/icons/ValidationErrorIcon.vue'

import Api from '@/api'

import {
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_CURRENT_ID_SLUG,
  GET_TERMINAL_CURRENT_REQUIRE_INN,
} from '@/store/actions'
import { autoAxle } from '@/constants/auto-type'
import { getLoginToken, numTrailerValidator } from '@/core'
import { mapGetters } from 'vuex'

export default {
  name: 'AdditionalFieldsForm',
  components: {
    TerminalBackButton,
    MainButton,
    SelectOptionsModal,
    SimpleInput,
    SimpleKeyboard,
    ValidationErrorIcon,
    InputChevronDown,
  },
  data() {
    return {
      additionalForm: {
        plate_trailer: '',
        axle_id: '',
        inn: '',
      },
      currentInput: '',
      supplierId: 0,
      rules: {
        plate_trailer: [
          {
            required: true,
            message: 'Укажите номер прицепа',
            trigger: 'change',
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (numTrailerValidator(value)) {
                callback(new Error('Формат номера должен быть "АА111111"'))
              }

              return callback()
            },
            message: 'Формат номера должен быть "CT176976"',
            trigger: 'change',
          },
        ],
        axle_id: [
          {
            required: true,
            message: 'Укажите количество осей',
            trigger: 'change',
          },
        ],
        inn: [
          {
            required: true,
            message: 'Заполните ИНН',
            trigger: 'change',
          },
          {
            min: 10,
            max: 10,
            message: 'ИНН состоит из 10 цифр',
            trigger: 'blur',
          },
          {
            required: true,
            validator: async (rule, value, callback) => {
              try {
                const { data } = await Api.drivers.getCommonSuppliersCheckInn(
                  getLoginToken(),
                  this.additionalForm.inn,
                )

                this.supplierId = data.id
              } catch {
                callback(new Error('Такого ИНН не существует'))
              }

              return callback()
            },
          },
        ],
      },
      axleList: [],
      currentAxle: '',
      isAxleOpen: false,
      plateTruck: '',
      firstForm: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalId: GET_TERMINAL_CURRENT_ID,
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      terminalRequireInn: GET_TERMINAL_CURRENT_REQUIRE_INN,
    }),
    isStep() {
      return this.terminalSlug === 'step'
    },
    preparedAxleList() {
      return this.axleList.map(item => ({
        id: item.val,
        value: item.name,
      }))
    },
  },
  mounted() {
    this.firstForm = this.$route.params.form
    this.plateTruck = this.firstForm.plate_truck
    this.axleList = autoAxle
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = {
              unload_id: this.terminalId,
              plate_truck: this.plateTruck,
              truck_type_code: this.firstForm.truck_type_code,
              culture_id: this.firstForm.culture_id,
              exporter_id: this.firstForm.exporter_id,
              phone: this.firstForm.phone,
              plate_trailer: this.additionalForm.plate_trailer,
              truck_spindles_count: this.additionalForm.axle_id,
            }

            if (this.terminalRequireInn) {
              params.common_supplier_id = this.supplierId
            }

            await Api.timeslots.timeslotCreate(params)

            await this.$router.push({
              name: 'terminal-finallyHandResponse',
              params: {
                phone: this.firstForm.phone,
                plate_truck: this.plateTruck,
              },
            })
          } catch (e) {
            await this.$router.push({
              name: 'terminal-limitHandResponse',
              params: { hasTimeslot: false },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },
    resetField(fieldName) {
      this.$refs[fieldName].clearValidate()
      this.currentInput = fieldName
    },
    backModal() {
      this.$router.push({
        name: 'terminal-handTimeslotForm',
        params: { form: this.firstForm },
      })
    },
    openAxleSelect() {
      this.$refs.axle_id.clearValidate()
      this.isAxleOpen = true
    },
    axleSelected(type) {
      this.currentAxle = this.axleList.find(item => item.val === type).name
      this.additionalForm.axle_id = type
      this.isAxleOpen = false
    },
    onChange(input) {
      this.additionalForm[this.currentInput] = input
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./styles"
.timeslot__form
  min-height: 238px
  display: flex
  flex-direction: column
  justify-content: center
</style>
