var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "timeslot",
    },
    [
      _c("TerminalBackButton", {
        staticClass: "timeslot__backButton",
        on: { clicked: _vm.backModal },
      }),
      _c(
        "div",
        { staticClass: "timeslot__container" },
        [
          _c("div", { staticClass: "timeslot__title" }, [
            _vm._v("Дополнительная информация"),
          ]),
          _c("div", { staticClass: "timeslot__truck" }, [
            _vm._v("Авто: " + _vm._s(_vm.plateTruck)),
          ]),
          _c("div", { staticClass: "timeslot__subtitle" }, [
            _vm._v(
              " При необходимости изменить ранее введенные данные - нажмите кнопку Назад "
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "additionalForm",
              staticClass: "timeslot__form",
              attrs: {
                model: _vm.additionalForm,
                "validate-on-rule-change": false,
              },
            },
            [
              _vm.isStep
                ? _c(
                    "div",
                    { staticClass: "timeslot__form-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "plate_trailer",
                          staticClass: "item",
                          attrs: {
                            prop: "plate_trailer",
                            rules: _vm.rules.plate_trailer,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "error",
                                fn: function ({ error }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "customErrors" },
                                      [
                                        _c("ValidationErrorIcon"),
                                        _c(
                                          "div",
                                          { staticClass: "customErrors__text" },
                                          [_vm._v(_vm._s(error))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2399969419
                          ),
                        },
                        [
                          _c("SimpleInput", {
                            attrs: { placeholder: "Номер прицепа" },
                            on: {
                              focus: function ($event) {
                                return _vm.resetField("plate_trailer")
                              },
                            },
                            model: {
                              value: _vm.additionalForm.plate_trailer,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.additionalForm,
                                  "plate_trailer",
                                  $$v
                                )
                              },
                              expression: "additionalForm.plate_trailer",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          ref: "axle_id",
                          staticClass: "item",
                          attrs: { prop: "axle_id", rules: _vm.rules.axle_id },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "error",
                                fn: function ({ error }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "customErrors" },
                                      [
                                        _c("ValidationErrorIcon"),
                                        _c(
                                          "div",
                                          { staticClass: "customErrors__text" },
                                          [_vm._v(_vm._s(error))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2399969419
                          ),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "timeslot__select",
                              on: { click: _vm.openAxleSelect },
                            },
                            [
                              _c(
                                "span",
                                {
                                  class: [
                                    "timeslot__select-content",
                                    _vm.currentAxle ? "value" : "placeholder",
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentAxle || "Количество осей"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("InputChevronDown"),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.terminalRequireInn
                ? _c(
                    "div",
                    { staticClass: "timeslot__form-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "inn",
                          staticClass: "item",
                          attrs: { prop: "inn", rules: _vm.rules.inn },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "error",
                                fn: function ({ error }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "customErrors" },
                                      [
                                        _c("ValidationErrorIcon"),
                                        _c(
                                          "div",
                                          { staticClass: "customErrors__text" },
                                          [_vm._v(_vm._s(error))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2399969419
                          ),
                        },
                        [
                          _c("SimpleInput", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##########",
                                expression: "'##########'",
                              },
                            ],
                            attrs: { placeholder: "ИНН" },
                            on: {
                              focus: function ($event) {
                                return _vm.resetField("inn")
                              },
                            },
                            model: {
                              value: _vm.additionalForm.inn,
                              callback: function ($$v) {
                                _vm.$set(_vm.additionalForm, "inn", $$v)
                              },
                              expression: "additionalForm.inn",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("MainButton", {
                staticClass: "timeslot__form-button",
                attrs: {
                  title: "Стать в общую очередь",
                  type: "primary",
                  size: "big",
                },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.submit("additionalForm")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "simpleKeyboard" },
        [
          _c("SimpleKeyboard", {
            staticClass: "simpleKeyboard__board",
            attrs: { input: _vm.additionalForm[_vm.currentInput] },
            on: { onChange: _vm.onChange },
          }),
        ],
        1
      ),
      _vm.isAxleOpen
        ? _c("SelectOptionsModal", {
            attrs: {
              title: "Укажите количество осей",
              list: _vm.preparedAxleList,
              active: _vm.additionalForm.axle_id,
            },
            on: {
              cancel: function ($event) {
                _vm.isAxleOpen = false
              },
              accept: _vm.axleSelected,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }